import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';
import { AssetWithDevices } from '../../AssetsHelpGang';
import { useCallback } from 'react';

interface Props {
    data: AssetWithDevices[] | null;
    show: boolean;
    onClose: () => void;
}

export const DevDebugDialog = ({ show, onClose, data }: Props) => (
    <SimpleDialog content={<Content data={data} />} show={show} onClose={onClose} showCloseButton={true} />
);

const Content = ({ data }: { data: AssetWithDevices[] | null }) => {
    const getNumberOfActiveAssetsWithoutDevice = useCallback(
        () =>
            data
                ? data.filter((asset) => asset.devices.length === 0 && asset.asset.status === 'active').length
                : undefined,
        [data]
    );

    const getNumberOfArchivedAssets = useCallback(
        () => (data ? data.filter((asset) => asset.asset.status === 'archived').length : undefined),
        [data]
    );

    return (
        <>
            <div>
                <span className={'margin-right-5'}>Number of active assets without device:</span>
                {getNumberOfActiveAssetsWithoutDevice()}
            </div>
            <div>
                <span className={'margin-right-5'}>Number of archived assets:</span>
                {getNumberOfArchivedAssets()}
            </div>
        </>
    );
};
