import './App.css';

import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import { DEFAULT_ROUTE, DEVICE_STATE_ROUTE, IOT_EVENTS_ROUTE, RENTAL } from './routes/routes';
import DefaultRedirect from './routes/DefaultRedirect';

import AppHeader from './header/AppHeader';
import AssetsHelpGang from './AssetsHelpGang';
import { DeviceStateWrapper } from './DeviceStateWrapper';
import { IotEventsWrapper } from './IotEventsWrapper.tsx';
import { EmbeddedRental } from './EmbeddedRental';

const App = () => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    if (!displayMessages || !userLocale) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout className={'StarterTemplate'}>
                <ApplicationLayout.Header>
                    <AppHeader />
                </ApplicationLayout.Header>
                <ApplicationLayout.Body>
                    <NotificationsContainer />
                    <Routes>
                        <Route path={DEFAULT_ROUTE} element={<AssetsHelpGang />} />
                        <Route path={DEVICE_STATE_ROUTE} element={<DeviceStateWrapper />} />
                        <Route path={IOT_EVENTS_ROUTE} element={<IotEventsWrapper />} />
                        <Route path={RENTAL} element={<EmbeddedRental />} />
                        <Route path="*" element={<DefaultRedirect />} />
                    </Routes>
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;
