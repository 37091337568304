import { useState } from 'react';
import { AssetsTable } from './assets/AssetsTable';
import { Asset, AssetStatus, Device } from '../api/assetAdministrationBackendCodec';
import { fetchAssets } from './requestHelper';
import { AssetDeletionDialog } from './assets/dialogs/AssetDeletionDialog';
import { AssetArchivingDialog } from './assets/dialogs/AssetArchivingDialog';
import {
    activateAsset,
    archiveAsset,
    associateDeviceToAsset,
    deleteAsset,
    disassociateDeviceFromAsset,
} from '../api/api';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useIntl } from 'react-intl';
import { AssetActivationDialog } from './assets/dialogs/AssetActivationDialog';
import { AssociateDeviceDialog } from './assets/dialogs/AssociateDeviceDialog';
import { sleep } from '../../utils';
import { DisassociateDeviceDialog } from './assets/dialogs/DisassociateDeviceDialog';
import { SearchHeader } from './assets/searchBar/SearchHeader';
import { searchIdentifier, SearchIdentifier } from './assets/searchBar/identifierSearchInput';
import { IdentifierResults, RequestedIdentifierResults } from './IdentifierResults';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { setIsDeletePending } from './appSlice';
import { ContractDialog } from './assets/dialogs/ContractDialog.tsx';
import { getAccountsWithContracts } from '../api/contracts/getAccountsWithContracts.ts';
import { ContractDialogState, initialContractDialogState } from './assets/dialogs/contractDialogUtils';

export interface AssetWithDevices {
    asset: Asset;
    devices: Device[];
}

export interface Search {
    accountId: string;
    searchIdentifierValue: string;
    searchIdentifierType: SearchIdentifier;
}

export interface MinimalAsset {
    id: string;
    vin: string | null | undefined;
    accountId: string;
    status: AssetStatus;
}

const AssetsHelpGang = () => {
    const [search, setSearch] = useState<Search>({
        accountId: '',
        searchIdentifierValue: '',
        searchIdentifierType: searchIdentifier.SEARCH_VIN,
    });
    const [assets, setAssets] = useState<AssetWithDevices[] | null>(null);
    const [requestedIdentifiers, setRequestedIdentifiers] = useState<RequestedIdentifierResults[]>([]);
    const [pending, setPending] = useState(false);
    const [assetsToBeModified, setAssetsToBeModified] = useState<MinimalAsset[]>([]);
    const [deviceToDisassociate, setDeviceToDisassociate] = useState<Device | null>(null);
    const [showDeletionDialog, setShowDeletionDialog] = useState(false);
    const [showArchivingDialog, setShowArchivingDialog] = useState(false);
    const [showAssociateDeviceDialog, setShowAssociateDeviceDialog] = useState(false);
    const [showDisassociateDeviceDialog, setShowDisassociateDeviceDialog] = useState(false);
    const [showActivationDialog, setShowActivationDialog] = useState(false);
    const [contractDialogState, setContractDialogState] = useState<ContractDialogState>(initialContractDialogState);

    const updateContractDialogState = (newState: Partial<ContractDialogState>) => {
        setContractDialogState((prevState) => ({ ...prevState, ...newState }));
    };

    const intl = useIntl();

    const dispatch = useAppDispatch();

    const initiateDeleteAssets = async (assetsToDelete: MinimalAsset[]) => {
        setAssetsToBeModified(assetsToDelete);
        updateContractDialogState({ intent: 'delete', requestState: 'loading', showDialog: true });
        try {
            const accountsWithContracts = await getAccountsWithContracts(assetsToDelete);
            if (accountsWithContracts.length > 0) {
                updateContractDialogState({
                    requestState: 'resolved',
                    accountsWithContracts: accountsWithContracts,
                });
            } else {
                updateContractDialogState({
                    showDialog: false,
                });
                setShowDeletionDialog(true);
            }
        } catch (error) {
            console.error(`Could not initiate delete assets: ${error}`);
            updateContractDialogState({ requestState: 'error' });
        }
    };

    const initiateArchiveAssets = async (assetsToArchive: MinimalAsset[]) => {
        updateContractDialogState({ intent: 'archive', requestState: 'loading', showDialog: true });
        setAssetsToBeModified(assetsToArchive);
        try {
            const accountsWithContracts = await getAccountsWithContracts(assetsToArchive);
            if (accountsWithContracts.length > 0) {
                updateContractDialogState({
                    accountsWithContracts: accountsWithContracts,
                    requestState: 'resolved',
                });
            } else {
                updateContractDialogState({
                    showDialog: false,
                });
                setShowArchivingDialog(true);
            }
        } catch (error) {
            console.error(`Could initiate archive assets ${error}`);
            updateContractDialogState({ requestState: 'error' });
        }
    };

    const initiateCheckContracts = async (assetsToCheck: MinimalAsset[]) => {
        setAssetsToBeModified(assetsToCheck);
        updateContractDialogState({ intent: 'check', requestState: 'loading', showDialog: true });
        try {
            const accountsWithActiveContracts = await getAccountsWithContracts(assetsToCheck);
            updateContractDialogState({
                accountsWithContracts: accountsWithActiveContracts,
                requestState: 'resolved',
            });
        } catch (error) {
            console.error(`Could not initiate check contracts: ${error}`);
            updateContractDialogState({ requestState: 'error', errorMessage: (error as Error).message });
        }
    };

    const initiateActivateAsset = (asset: MinimalAsset) => {
        setAssetsToBeModified([asset]);
        setShowActivationDialog(true);
    };

    const initiateAssociateDevice = (asset: MinimalAsset) => {
        setAssetsToBeModified([asset]);
        setShowAssociateDeviceDialog(true);
    };

    const initiateDisassociateDevice = (asset: MinimalAsset, device: Device) => {
        setAssetsToBeModified([asset]);
        setDeviceToDisassociate(device);
        setShowDisassociateDeviceDialog(true);
    };

    const handleDeleteConfirm = (assetIds: string[], reason: string) => {
        dispatch(setIsDeletePending(true));
        Promise.allSettled(assetIds.map((assetId) => deleteAsset(assetId, reason)))
            .then((results) => {
                const failures = results.filter((result) => result.status === 'rejected');
                const success = results.filter((result) => result.status === 'fulfilled');

                if (failures.length > 0) {
                    failures.forEach((failure) => {
                        console.error(`Could not delete asset: ${(failure as PromiseRejectedResult).reason}`);
                    });
                    Notification.error(
                        failures.length === 1
                            ? intl.formatMessage({
                                  id: 'assets-helpgang.notification.couldNotDeleteAsset.one',
                              })
                            : intl.formatMessage(
                                  {
                                      id: 'assets-helpgang.notification.couldNotDeleteAsset.other',
                                  },
                                  { amount: failures.length }
                              )
                    );
                }

                if (success.length > 0) {
                    Notification.success(success.length === 1 ? 'Asset deleted' : `${success.length} Assets deleted`);
                }

                return sleep(1000);
            })
            .then(() => fetchAssets(search, setAssets, setRequestedIdentifiers))
            .then(() => dispatch(setIsDeletePending(false)))
            .catch((error) => {
                console.error(`Unexpected error: ${error}`);
                Notification.error(intl.formatMessage({ id: 'assets-helpgang.notification.unexpectedError' }));
                dispatch(setIsDeletePending(false));
            });
        setShowDeletionDialog(false);
    };

    const handleArchiveConfirm = (assetIds: string[], reason: string) => {
        Promise.allSettled(assetIds.map((assetId) => archiveAsset(assetId, reason)))
            .then((results) => {
                const failures = results.filter((result) => result.status === 'rejected');
                const success = results.filter((result) => result.status === 'fulfilled');

                if (failures.length > 0) {
                    failures.forEach((failure) => {
                        console.error(`Could not archive asset: ${(failure as PromiseRejectedResult).reason}`);
                    });
                    Notification.error(
                        failures.length === 1
                            ? intl.formatMessage({
                                  id: 'assets-helpgang.notification.couldNotArchiveAsset.one',
                              })
                            : intl.formatMessage(
                                  {
                                      id: 'assets-helpgang.notification.couldNotArchiveAsset.other',
                                  },
                                  { amount: failures.length }
                              )
                    );
                }

                if (success.length > 0) {
                    Notification.success(success.length === 1 ? 'Asset archived' : `${success.length} Assets archived`);
                }

                return sleep(1000);
            })
            .then(() => fetchAssets(search, setAssets, setRequestedIdentifiers))
            .catch((error) => {
                console.error(`Unexpected error: ${error}`);
                Notification.error(intl.formatMessage({ id: 'assets-helpgang.notification.unexpectedError' }));
            });
        setShowArchivingDialog(false);
    };

    const handleActivateConfirm = (assetId: string, reason: string) => {
        activateAsset(assetId, reason)
            .then(() => sleep(1000))
            .then(() => fetchAssets(search, setAssets, setRequestedIdentifiers))
            .catch((error) => {
                console.error(`Could not activate asset: ${error}`);
                Notification.error(intl.formatMessage({ id: 'assets-helpgang.notification.couldNotActivateAsset' }));
            });
        setShowActivationDialog(false);
    };

    const handleAssociateDeviceConfirm = (
        assetId: string,
        deviceIdentification: string,
        deviceType: string,
        reason: string
    ) => {
        associateDeviceToAsset(assetId, deviceIdentification, deviceType, reason)
            .then(() => sleep(500))
            .then(() => fetchAssets(search, setAssets, setRequestedIdentifiers))
            .catch((error) => {
                console.error(`Could not associate asset: ${error}`);
                Notification.error(intl.formatMessage({ id: 'assets-helpgang.notification.couldNotActivateAsset' }));
            });
        setShowAssociateDeviceDialog(false);
    };

    const handleDisassociateDeviceConfirm = (assetId: string, deviceId: string, reason: string) => {
        disassociateDeviceFromAsset(assetId, deviceId, reason)
            .then(() => sleep(500))
            .then(() => fetchAssets(search, setAssets, setRequestedIdentifiers))
            .catch((error) => {
                console.error(`Could not disassociate asset: ${error}`);
                Notification.error(intl.formatMessage({ id: 'assets-helpgang.notification.couldNotActivateAsset' }));
            });
        setShowDisassociateDeviceDialog(false);
    };

    const handleFetchAssets = async () => {
        setPending(true);
        setRequestedIdentifiers([]);
        await fetchAssets(search, setAssets, setRequestedIdentifiers);
        setPending(false);
    };
    return (
        <div className={'assetsHelpGang'}>
            <AssetDeletionDialog
                showDialog={showDeletionDialog}
                setShowDeletionDialog={setShowDeletionDialog}
                assets={assetsToBeModified}
                handleDeleteConfirm={handleDeleteConfirm}
            />
            <ContractDialog
                state={contractDialogState}
                updateState={updateContractDialogState}
                handleConfirm={() => {
                    if (contractDialogState.intent == 'archive') {
                        setShowArchivingDialog(true);
                    } else if (contractDialogState.intent == 'delete') {
                        setShowDeletionDialog(true);
                    }
                }}
                handleRefresh={async () => {
                    if (contractDialogState.intent == 'archive') {
                        await initiateArchiveAssets(assetsToBeModified);
                    } else if (contractDialogState.intent == 'delete') {
                        await initiateDeleteAssets(assetsToBeModified);
                    } else if (contractDialogState.intent == 'check') {
                        await initiateCheckContracts(assetsToBeModified);
                    }
                }}
            />
            <AssetArchivingDialog
                showArchivingDialog={showArchivingDialog}
                setShowArchivingDialog={setShowArchivingDialog}
                assets={assetsToBeModified}
                handleArchiveConfirm={handleArchiveConfirm}
            />
            <AssetActivationDialog
                showActivationDialog={showActivationDialog}
                setShowActivationDialog={setShowActivationDialog}
                asset={assetsToBeModified[0]}
                handleActivateConfirm={handleActivateConfirm}
            />
            <AssociateDeviceDialog
                showDialog={showAssociateDeviceDialog}
                setShowDialog={setShowAssociateDeviceDialog}
                asset={assetsToBeModified[0]}
                handleConfirm={handleAssociateDeviceConfirm}
            />
            <DisassociateDeviceDialog
                showDialog={showDisassociateDeviceDialog}
                setShowDialog={setShowDisassociateDeviceDialog}
                asset={assetsToBeModified[0]}
                device={deviceToDisassociate}
                handleConfirm={handleDisassociateDeviceConfirm}
            />

            <SearchHeader
                pending={pending}
                search={search}
                setSearch={setSearch}
                triggerFetchAssets={handleFetchAssets}
            />
            <IdentifierResults requestedIdentifierResults={requestedIdentifiers} />

            <AssetsTable
                assetsWithDevices={assets}
                initiateDeleteAssets={initiateDeleteAssets}
                initiateArchiveAssets={initiateArchiveAssets}
                initiateCheckContracts={initiateCheckContracts}
                initiateActivateAsset={initiateActivateAsset}
                initiateAssociateDevice={initiateAssociateDevice}
                initiateDisassociateDevice={initiateDisassociateDevice}
            />
        </div>
    );
};

export default AssetsHelpGang;
