export type AccountWithAssets = {
    accountId: string;
    assetIds: string[];
};
export type ContractDialogState = {
    showDialog: boolean;
    requestState: 'loading' | 'resolved' | 'error' | undefined;
    accountsWithContracts: AccountWithAssets[];
    errorMessage: string | undefined;
    checkBoxChecked: boolean;
    intent: 'archive' | 'delete' | 'check' | undefined;
};

export const initialContractDialogState = {
    showDialog: false,
    requestState: undefined,
    accountsWithContracts: [],
    errorMessage: undefined,
    checkBoxChecked: false,
    intent: undefined,
};
