import { MinimalAsset } from '../../app/AssetsHelpGang.tsx';
import { fetchContractsByAccountAndAssetIds } from '../api.ts';
import { Contract } from './productActivationBackendCodec.ts';
import { AccountWithAssets } from '../../app/assets/dialogs/contractDialogUtils';

const hasContracts = async (accountWithAssets: AccountWithAssets): Promise<boolean> => {
    try {
        const response: Contract[] = await fetchContractsByAccountAndAssetIds(
            accountWithAssets.accountId,
            accountWithAssets.assetIds
        );
        return response.length > 0;
    } catch (err) {
        console.error(`Could not resolve contracts for account ${accountWithAssets.accountId}:`, err);
        throw err;
    }
};

export const getAccountsWithContracts = async (assetsToArchive: MinimalAsset[]): Promise<AccountWithAssets[]> => {
    if (assetsToArchive.length > 100) throw Error('TOO_MANY_ASSETS_SELECTED');

    const accountsWithAssets = groupAssetsByAccount(assetsToArchive);
    const accountsWithContractsInformation: {
        accountWithAssets: AccountWithAssets;
        hasContracts: boolean;
    }[] = await Promise.all(
        accountsWithAssets.map(async (accountWithAssets) => ({
            accountWithAssets,
            hasContracts: await hasContracts(accountWithAssets),
        }))
    );

    return accountsWithContractsInformation
        .filter(({ hasContracts }) => hasContracts)
        .map(({ accountWithAssets }) => accountWithAssets);
};

const groupAssetsByAccount = (assets: MinimalAsset[]): AccountWithAssets[] => {
    const grouped = new Map<string, string[]>();

    for (const { accountId, id } of assets) {
        if (!grouped.has(accountId)) {
            grouped.set(accountId, []);
        }
        grouped.get(accountId)!.push(id);
    }

    return Array.from(grouped, ([accountId, assetIds]) => ({ accountId, assetIds }));
};
