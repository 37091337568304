import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import LoadMoreButton from '@rio-cloud/rio-uikit/LoadMoreButton';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { AssetWithDevices, MinimalAsset } from '../AssetsHelpGang';
import { Device } from '../../api/assetAdministrationBackendCodec';
import { TableRow } from './TableRow';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getIsDeletePending } from '../appSlice';
import { config } from '../../../config';
import { DevDebugDialog } from './dialogs/DevDebugDialog';

const displayDevDebugInfo = localStorage.getItem('displayDevDebugInfo') === 'true';

const ASSETS_TO_DISPLAY_INITIALLY = parseInt(config.assetsToDisplayInitially ?? '100', 10);
const ASSETS_TO_DISPLAY_INCREMENT = parseInt(config.assetsToDisplayIncrement ?? '100', 10);

const defaultColumnOrder = [
    'vin',
    'assetId',
    'type',
    'accountId',
    'name',
    'brand',
    'licensePlate',
    'status',
    'dataSources',
] as const;
export type ColumnName = (typeof defaultColumnOrder)[number];

const columnLabels: Record<ColumnName, React.ReactNode> = {
    vin: 'VIN',
    assetId: 'Asset Id',
    type: 'Asset Id',
    accountId: 'Account Id',
    name: 'Name',
    brand: 'Brand',
    licensePlate: 'License Plate',
    status: 'Status',
    dataSources: 'Data Sources [type | device-identification | device-id]',
};

const tableClassNames =
    'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled';

interface AssetsTableProps {
    assetsWithDevices: AssetWithDevices[] | null;
    initiateDeleteAssets: (asset: MinimalAsset[]) => void;
    initiateArchiveAssets: (asset: MinimalAsset[]) => void;
    initiateCheckContracts: (asset: MinimalAsset[]) => void;
    initiateAssociateDevice: (asset: MinimalAsset) => void;
    initiateActivateAsset: (asset: MinimalAsset) => void;
    initiateDisassociateDevice: (asset: MinimalAsset, device: Device) => void;
}

export const AssetsTable = (props: AssetsTableProps) => {
    const {
        assetsWithDevices,
        initiateDeleteAssets,
        initiateArchiveAssets,
        initiateCheckContracts,
        initiateActivateAsset,
        initiateAssociateDevice,
        initiateDisassociateDevice,
    } = props;

    const isDeletePending = useAppSelector(getIsDeletePending);

    const columns = defaultColumnOrder;

    const [selectedRowId, setSelectedRowId] = useState('');
    const [checkedRowIds, setCheckedRowIds] = useState<string[]>([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [showDebugDialog, setShowDebugDialog] = useState(false);
    const [displayedRows, setDisplayedRows] = useState(ASSETS_TO_DISPLAY_INITIALLY);

    useEffect(() => {
        if (!isDeletePending && initialLoad) {
            setInitialLoad(false);
        } else if (!isDeletePending) {
            setCheckedRowIds([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeletePending, setCheckedRowIds, setInitialLoad]);

    useEffect(() => {
        if (assetsWithDevices) {
            setDisplayedRows(ASSETS_TO_DISPLAY_INITIALLY);
        }
    }, [assetsWithDevices]);

    const intl = useIntl();

    const handleClickOnRow = (rowId: string) =>
        rowId === selectedRowId ? setSelectedRowId('') : setSelectedRowId(rowId);

    const handleBulkSelect = (arg: 'all' | 'none') => {
        if (arg === 'all') {
            setCheckedRowIds(assetsWithDevices?.map((asset) => asset.asset.id) || []);
        } else {
            setCheckedRowIds([]);
        }
    };

    const handleBulkArchive = () => {
        const assetsToArchive = getSelectedAssets(checkedRowIds, assetsWithDevices);
        initiateArchiveAssets(assetsToArchive);
    };

    const handleLoadMore = () =>
        setDisplayedRows((prev) => {
            const sum = prev + ASSETS_TO_DISPLAY_INCREMENT;
            return assetsWithDevices && sum > assetsWithDevices.length ? assetsWithDevices?.length : sum;
        });

    const handleBulkDelete = () => {
        const assetsToDelete = getSelectedAssets(checkedRowIds, assetsWithDevices);
        initiateDeleteAssets(assetsToDelete);
    };

    const handleBulkCheckContracts = () => {
        const assetsToCheck = getSelectedAssets(checkedRowIds, assetsWithDevices);
        initiateCheckContracts(assetsToCheck);
    };

    const batchButton = (
        <ButtonDropdown
            title={<span className="rioglyph rioglyph rioglyph-checkboxes" />}
            className="btn-s"
            variant="link"
            iconOnly
            items={[
                {
                    value: (
                        <div>
                            <FormattedMessage id={'assets-helpgang.bulkAction.selectAll'} />
                        </div>
                    ),
                    onSelect: () => handleBulkSelect('all'),
                },
                {
                    value: (
                        <div>
                            <FormattedMessage id={'assets-helpgang.bulkAction.deselectAll'} />
                        </div>
                    ),
                    onSelect: () => handleBulkSelect('none'),
                },
                {
                    divider: true,
                },
                {
                    value: (
                        <div>
                            <span className="rioglyph rioglyph rioglyph-inbox-in margin-right-10 text-size-18" />
                            <FormattedMessage id={'assets-helpgang.bulkAction.archiveSelected'} />
                        </div>
                    ),
                    onSelect: handleBulkArchive,
                    disabled: checkedRowIds.length === 0,
                },
                {
                    value: (
                        <div>
                            <span className="rioglyph rioglyph-trash margin-right-10 text-size-18" />
                            <FormattedMessage id={'assets-helpgang.bulkAction.deleteSelected'} />
                        </div>
                    ),
                    onSelect: handleBulkDelete,
                    disabled: checkedRowIds.length === 0,
                },
                {
                    value: (
                        <div>
                            <span className="rioglyph rioglyph-document margin-right-10 text-size-18" />
                            <FormattedMessage id={'assets-helpgang.bulkAction.checkContractsForSelected'} />
                        </div>
                    ),
                    onSelect: handleBulkCheckContracts,
                    disabled: checkedRowIds.length === 0,
                },
            ]}
        />
    );

    const renderTableContent = (): React.ReactNode => {
        if (assetsWithDevices && assetsWithDevices.length > 0) {
            return (
                <>
                    {displayDevDebugInfo && (
                        <>
                            <button className={'btn btn-default margin-10'} onClick={() => setShowDebugDialog(true)}>
                                Show details
                            </button>
                            <DevDebugDialog
                                onClose={() => setShowDebugDialog(false)}
                                show={showDebugDialog}
                                data={assetsWithDevices}
                            />
                        </>
                    )}
                    <table className={tableClassNames} style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}>
                        <thead>
                            <tr>
                                <th className="table-checkbox">{batchButton}</th>
                                <th>{columnLabels.vin}</th>
                                <th>{columnLabels.assetId}</th>
                                <th>{columnLabels.type}</th>
                                <th>{columnLabels.accountId}</th>
                                <th>{columnLabels.name}</th>
                                <th>{columnLabels.brand}</th>
                                <th>{columnLabels.licensePlate}</th>
                                <th>{columnLabels.status}</th>
                                <th>{columnLabels.dataSources}</th>
                                <th className="table-action" />
                            </tr>
                        </thead>
                        <tbody>
                            {assetsWithDevices.slice(0, displayedRows).map((assetWithDevices) => (
                                <TableRow
                                    row={assetWithDevices}
                                    key={assetWithDevices.asset.id}
                                    columns={columns}
                                    colLabels={columnLabels}
                                    onClick={() => handleClickOnRow(assetWithDevices.asset.id)}
                                    isActive={selectedRowId === assetWithDevices.asset.id}
                                    isChecked={checkedRowIds.includes(assetWithDevices.asset.id)}
                                    isIdentificationMultipleTimesInResult={
                                        assetsWithDevices.filter(
                                            (it) => it.asset.identification === assetWithDevices.asset.identification
                                        ).length > 1
                                    }
                                    toggleIsChecked={() =>
                                        setCheckedRowIds((prevState) => {
                                            const newSet = new Set(prevState);
                                            if (newSet.has(assetWithDevices.asset.id)) {
                                                newSet.delete(assetWithDevices.asset.id);
                                            } else {
                                                newSet.add(assetWithDevices.asset.id);
                                            }
                                            return Array.from(newSet);
                                        })
                                    }
                                    initiateActivateAsset={initiateActivateAsset}
                                    initiateArchiveAssets={initiateArchiveAssets}
                                    initiateAssociateDevice={initiateAssociateDevice}
                                    initiateDeleteAssets={initiateDeleteAssets}
                                    initiateDisassociateDevice={initiateDisassociateDevice}
                                />
                            ))}
                        </tbody>
                    </table>
                    <LoadMoreButton
                        loaded={Math.min(displayedRows, assetsWithDevices.length)}
                        total={assetsWithDevices.length}
                        onLoadMore={handleLoadMore}
                        loadMoreMessage={intl.formatMessage({ id: 'assets-helpgang.loadMore' })}
                    />
                </>
            );
        } else if (assetsWithDevices == null) {
            return null;
        } else {
            return (
                <NotFoundState
                    headline={intl.formatMessage({ id: 'assets-helpgang.search.nothingFound' })}
                    message={intl.formatMessage({ id: 'assets-helpgang.search.refineSearch' })}
                />
            );
        }
    };

    return <div id={'Assets'}>{renderTableContent()}</div>;
};

const getSelectedAssets = (checkedRowIds: string[], assetsWithDevices: AssetWithDevices[] | null): MinimalAsset[] => {
    return checkedRowIds
        .map((assetId) => {
            const assetToCheck = assetsWithDevices?.find((it) => it.asset.id === assetId);
            return assetToCheck
                ? {
                      id: assetToCheck.asset.id,
                      vin: assetToCheck.asset.identification,
                      accountId: assetToCheck.asset.account_id,
                      status: assetToCheck.asset.status,
                  }
                : null;
        })
        .filter((asset): asset is MinimalAsset => asset !== null);
};
