import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import React from 'react';
import { useIntl } from 'react-intl';
import { AssetWithDevices, MinimalAsset } from '../AssetsHelpGang';
import { AssetStatus, Device } from '../../api/assetAdministrationBackendCodec';
import { ColumnName } from './AssetsTable';
import { ValueWithCopyButton } from '../../components/ValueWithCopyButton';
import { AssetType } from './AssetType';
import { AccountIdWithName } from './components/AccountIdWithName';

interface TableRowProps {
    row: AssetWithDevices;
    columns: readonly string[]; // { name: string; withCopy?: boolean }[];
    colLabels: Record<ColumnName, React.ReactNode>;
    onClick: (event: React.MouseEvent<HTMLTableRowElement>) => void;
    isActive: boolean;
    isChecked: boolean;
    isIdentificationMultipleTimesInResult: boolean;
    toggleIsChecked: () => void;

    initiateActivateAsset: (asset: MinimalAsset) => void;
    initiateArchiveAssets: (asset: MinimalAsset[]) => void;
    initiateAssociateDevice: (asset: MinimalAsset) => void;
    initiateDeleteAssets: (asset: MinimalAsset[]) => void;
    initiateDisassociateDevice: (asset: MinimalAsset, device: Device) => void;
}

export const TableRow = React.memo(
    function TableRow(props: TableRowProps) {
        const {
            row,
            // columns,
            colLabels,
            onClick,
            isActive,
            isChecked,
            isIdentificationMultipleTimesInResult,
            toggleIsChecked,
            initiateActivateAsset,
            initiateArchiveAssets,
            initiateAssociateDevice,
            initiateDeleteAssets,
            initiateDisassociateDevice,
        } = props;

        const intl = useIntl();

        return (
            <tr
                key={row.asset.id}
                data-key={row.asset.id}
                onClick={onClick}
                className={isActive ? 'active' : ''}
                data-cy="assets-table-row"
            >
                <td className="table-checkbox">
                    <Checkbox checked={isChecked} onClick={toggleIsChecked} />
                </td>

                <td data-field={colLabels.vin}>
                    <div className={'display-flex'}>
                        {isIdentificationMultipleTimesInResult && row.asset.identification && (
                            <span
                                className="rioglyph rioglyph-duplicate rioglyph-filled text-color-warning margin-right-10"
                                title={`${row.asset.identification_type} appears multiple times in search result`}
                            />
                        )}
                        <ValueWithCopyButton value={row.asset.identification} />
                    </div>
                </td>
                <td data-field={colLabels.assetId}>
                    <ValueWithCopyButton value={row.asset.id} />
                </td>
                <td data-field={colLabels.type}>
                    <AssetType type={row.asset.type} />
                </td>
                <td data-field={colLabels.accountId}>
                    <ValueWithCopyButton value={row.asset.account_id} tooltipText={'Copy Id'}>
                        <AccountIdWithName accountId={row.asset.account_id} />
                    </ValueWithCopyButton>
                </td>
                <td data-field={colLabels.name}>{row.asset.name}</td>
                <td data-field={colLabels.brand}>{row.asset.brand}</td>
                <td data-field={colLabels.licensePlate}>{row.asset.license_plate}</td>
                <td data-field={colLabels.status}>{row.asset.status}</td>
                <td data-field={colLabels.dataSources}>
                    <table className={'table table-head-filled table-condensed'} style={{ outline: 'none' }}>
                        <tbody>
                            {row.devices.map((device) => (
                                <tr key={device.id}>
                                    <DeviceTd>{device.type}</DeviceTd>
                                    <DeviceTd dataCy={'device-identification'}>{device.identification}</DeviceTd>
                                    <DeviceTd dataCy={'device-id'}>
                                        <ValueWithCopyButton value={device.id} width={7} />
                                    </DeviceTd>
                                    <DeviceTd dataCy={'disassociate-device'} textAlign={'right'}>
                                        <button
                                            className="btn btn-link btn-link-inline"
                                            type="button"
                                            id="disassociateDevice"
                                            data-cy="disassociate-device-button"
                                            onClick={() =>
                                                initiateDisassociateDevice(
                                                    {
                                                        id: row.asset.id,
                                                        accountId: row.asset.account_id,
                                                        vin: row.asset.identification,
                                                        status: row.asset.status,
                                                    },
                                                    device
                                                )
                                            }
                                        >
                                            <span className="rioglyph rioglyph-trash" />
                                        </button>
                                    </DeviceTd>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </td>

                <td className="table-action">
                    {
                        <button
                            data-cy="associate-device-button"
                            className={'btn btn-primary x-asset-action-btn'}
                            data-key={`associate-${row.asset.id}`}
                            onClick={() =>
                                initiateAssociateDevice({
                                    id: row.asset.id,
                                    vin: row.asset.identification,
                                    accountId: row.asset.account_id,
                                    status: row.asset.status,
                                })
                            }
                        >
                            <span className="rioglyph rioglyph-plus-light" aria-hidden="true" />
                            Device
                        </button>
                    }
                    {row.asset.status === AssetStatus.ACTIVE ? (
                        <button
                            data-cy="archive-asset-button"
                            className={'btn btn-primary x-asset-action-btn'}
                            data-key={`archive-${row.asset.id}`}
                            onClick={() =>
                                initiateArchiveAssets([
                                    {
                                        id: row.asset.id,
                                        vin: row.asset.identification,
                                        accountId: row.asset.account_id,
                                        status: row.asset.status,
                                    },
                                ])
                            }
                        >
                            <span className={'rioglyph rioglyph rioglyph-inbox-in'} />
                            Archive
                        </button>
                    ) : (
                        <button
                            data-cy="activate-asset-button"
                            className={'btn btn-primary x-asset-action-btn'}
                            data-key={`activate-${row.asset.id}`}
                            onClick={() =>
                                initiateActivateAsset({
                                    id: row.asset.id,
                                    vin: row.asset.identification,
                                    accountId: row.asset.account_id,
                                    status: row.asset.status,
                                })
                            }
                        >
                            <span className={'rioglyph rioglyph rioglyph-inbox-out'} />
                            Activate
                        </button>
                    )}
                    <span
                        data-cy="delete-asset-button"
                        className="rioglyph rioglyph-trash text-color-danger text-size-16"
                        data-key={`delete-${row.asset.id}`}
                        onClick={() =>
                            initiateDeleteAssets([
                                {
                                    id: row.asset.id,
                                    vin: row.asset.identification,
                                    accountId: row.asset.account_id,
                                    status: row.asset.status,
                                },
                            ])
                        }
                        aria-hidden={'true'}
                        style={{ cursor: 'pointer', margin: '10pt' }}
                        title={intl.formatMessage({ id: 'assets-helpgang.delete.hover' })}
                    />
                </td>
            </tr>
        );
    },
    (prevProps, nextProps) => {
        return JSON.stringify(prevProps) === JSON.stringify(nextProps);
    }
);

const DeviceTd = (props: React.PropsWithChildren<{ dataCy?: string; textAlign?: 'right' }>) => (
    <td style={{ paddingTop: '0pt', paddingBottom: '0pt', textAlign: props.textAlign }} data-cy={props.dataCy}>
        {props.children}
    </td>
);

// const generateTdEntry = (
//     col: Column,
//     row: AssetWithDevices,
//     actions: { initiateDisassociateDevice: (asset: MinimalAsset, device: Device) => void }
// ) => {
//     switch (col) {
//         case 'vin':
//             return (
//                 <td>
//                     <ValueWithCopyButton value={row.asset.identification} />
//                 </td>
//             );
//         case 'assetId':
//             return (
//                 <td>
//                     <ValueWithCopyButton value={row.asset.identification} />
//                 </td>
//             );
//         case 'accountId':
//             return (
//                 <td>
//                     <ValueWithCopyButton value={row.asset.account_id} tooltipText={'Copy Id'}>
//                         <AccountIdWithName accountId={row.asset.account_id} />
//                     </ValueWithCopyButton>
//                 </td>
//             );
//         case 'type':
//             return (
//                 <td>
//                     <AssetType type={row.asset.type} />
//                 </td>
//             );
//         case 'name':
//             return <td>{row.asset.name}</td>;
//         case 'brand':
//             return <td>{row.asset.brand}</td>;
//         case 'licensePlate':
//             return <td>{row.asset.license_plate}</td>;
//         case 'status':
//             return <td>{row.asset.status}</td>;
//         case 'dataSources':
//             return (
//                 <td>
//                     <table className={'table table-head-filled table-condensed'}>
//                         <tbody>
//                             {row.devices.map((device) => (
//                                 <tr key={device.id}>
//                                     <DeviceTd>{device.type}</DeviceTd>
//                                     <DeviceTd dataCy={'device-identification'}>{device.identification}</DeviceTd>
//                                     <DeviceTd dataCy={'device-id'}>
//                                         <ValueWithCopyButton value={device.id} width={7} />
//                                     </DeviceTd>
//                                     <DeviceTd dataCy={'disassociate-device'} textAlign={'right'}>
//                                         <button
//                                             className="btn btn-link btn-link-inline"
//                                             type="button"
//                                             id="disassociateDevice"
//                                             data-cy="disassociate-device-button"
//                                             onClick={() =>
//                                                 actions.initiateDisassociateDevice(
//                                                     {
//                                                         id: row.asset.id,
//                                                         accountId: row.asset.account_id,
//                                                         vin: row.asset.identification,
//                                                     },
//                                                     device
//                                                 )
//                                             }
//                                         >
//                                             <span className="rioglyph rioglyph-trash" />
//                                         </button>
//                                     </DeviceTd>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </td>
//             );
//     }
// };
